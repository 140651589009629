import CustomImage from '../../../../customComponents/customImage/CustomImage'
import images from '../../../../assets/images'
import {KTSVG} from '../../../../_metronic/helpers'
// import {firebaseDB} from '../../../../firebse/firebase'
// import {set, ref} from 'firebase/database'

const TabMaintancePage = ({tabName}) => {
    return (
        <div className='p-10 w-100'>
            <div className='d-flex flex-center align-items-center '>
                <CustomImage src={images.maintenance} className={'w-sm-40 w-80'} />
            </div>
            <h2
                className='d-flex flex-center align-items-center mt-5'
                style={{
                    fontWeight: '800',
                    color: '#2c5c95',
                }}
            >
                {tabName} Under Maintance...
            </h2>
            <h4
                className='  d-flex flex-center align-items-center'
                style={{
                    fontWeight: '800',
                    color: '#859fc0',
                }}
            >
                The {tabName} is currently undergoing scheduled maintenance.We should be back
                shortly.
            </h4>
            <div className='d-flex align-items-center justify-content-center w-100 mt-10'>
                <div
                    className='p-5'
                    style={{
                        border: 'none',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: 'fit-content',
                        borderRadius: '10px 10px 0px 0px',
                    }}
                >
                    <div className='text-cmGrey600'>
                        <span
                            className='app-sidebar-logo-default'
                            style={{fontWeight: '600', fontFamily: 'Manrope', fontSize: '12px'}}
                        >
                            Powered by
                        </span>

                        <span className=''>
                            <KTSVG
                                path='/media/icons/duotune/art/Sequifi-logo-with-text-inline.svg'
                                className='cursor-pointer'
                                svgClassName='w-100px h-30px'
                            />
                        </span>
                        <div
                            className='app-sidebar-logo-default'
                            style={{fontWeight: '600', fontFamily: 'Manrope', fontSize: '12px'}}
                        >
                            Don't Simplify, Just Sequifi it!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabMaintancePage
